import { graphql } from 'gatsby'
import React from 'react'
import Layout from '../components/layout'
import { RecipeListItem } from '../components/recipe-list-item'
import SEO from '../components/seo'

const BlogIndex = props => {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Opskrifter" />
      <h1>Opskrifter</h1>
      <ul>
        {posts.map(({ node }) => (
          <RecipeListItem
            key={node.fields.slug}
            slug={node.fields.slug}
            title={node.frontmatter.title}
          />
        ))}
      </ul>
    </Layout>
  )
}

export default BlogIndex

export const PageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { pageType: { eq: "recipe" } } }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
